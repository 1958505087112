import { BROWSE_VACANCIES_BY_SKILL_COUNTRY_CITY } from '@/constants/routes'
import VacancyLocation from '@/models-ts/vacancies/VacancyLocation'
import { RootState } from '@/store'
import Vue, { PropType } from 'vue'
import { mapState } from 'vuex'

export default Vue.extend<any, any, any, any>({
  props: {
    locations: Array as PropType<Array<VacancyLocation>>,
    category: Object,
    isMainClickable: Boolean,
  },
  computed: {
    ...mapState<RootState>({
      previousRoute: (state: RootState) => state.app.previousRoute,
    }),
    main () {
      let location
      let country = this.$route.params?.country || this.previousRoute?.params?.country
      let city = this.$route.params?.city || this.previousRoute?.params?.city
      if (country) {
        location = this.locations.find((l: VacancyLocation) => l.countrySlug === country && (!city || l.citySlug === city))
      }
      return location || this.locations[0]
    },
    other (): VacancyLocation[] {
      return this.locations.filter((l: VacancyLocation) => l.location !== this.main.location)
    },
  },
  methods: {
    getLink (location: VacancyLocation) {
      return {
        name: BROWSE_VACANCIES_BY_SKILL_COUNTRY_CITY,
        params: {
          skill: this.previousRoute?.params?.skill || this.category?.url,
          country: location.countrySlug,
          city: location.citySlug,
        },
      }
    },
    onClickMainLocation () {
      if (this.isMainClickable) {
        this.$router.push(this.getLink(this.main))
      }
    },
  }
})
